<template>
    <n-list value="expenses" text="開支" @updateShop='getShop' :shopOptions="shopOptions" :selectShop='true' :fields="fields" :items="expenseByShop" />
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
    data() {
        return {
            shop:null,
            expense: null,
            fields: [
                {
                    key: 'paidAt',
                    label: '日期',
                    sortable: true,
                    formatter: value => {
                        return moment(value).format('YYYY-MM-DD')
                    }
                },
                {
                    key: 'shop.name',
                    label: '分店',
                    sortable: true,
                },
                {
                    key: 'category.name',
                    label: '分類',
                    sortable: true,
                },
                {
                    key: 'description',
                    label: '描述',
                    class: 'd-none d-sm-table-cell',
                },
                {
                    key: 'grandTotal',
                    label: '總計',
                    formatter: value => {
                        return `$${value}`
                    },
                    sortable: true,
                },
            ]
        }
    },

    async created() {
        await this.$store.dispatch('bindCollectionByMerchant', { col: 'expenses', merchantId: localStorage.merchantId })
        await this.$store.dispatch('bindCollectionByMerchant', { col: 'shops', merchantId: localStorage.merchantId })
    },

    computed: {
        ...mapState({
            expenses: state => state.expenses,
            shops: state => state.shops,
        }),

        shopOptions() {
            if (!this.shops) return []
            let ret = this.shops.map(shop => {
                return {
                    text: shop.name,
                    value: {
                        id: shop.id,
                        name: shop.name,
                    }
                }
            })
            ret.unshift({ text: '全部分店', value: null })
            return ret
        },

        expenseByShop(){
            let res = []
            if (!this.shop){
                _.cloneDeep(this.expenses).forEach(expense => {
                return res.push(expense)
                })
            }
            else{ 
                _.cloneDeep(this.expenses).forEach(expense => {
                    if (expense.shop.id == this.shop.id) return res.push(expense)
                })
            }
            return res
        },
    },

    methods: {
        getShop(shop){
            this.shop = shop;
        },
    }
}
</script>